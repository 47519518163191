import { createAsyncThunk } from "@reduxjs/toolkit";
import { authPost } from "../../common/redux/ApiClient";
import { ITransactionReportRequest } from "../models/request";

export const getTransactionReport = createAsyncThunk(
  "sample/transactionreport",
  async (data: ITransactionReportRequest) => {
    return await authPost(`${process.env.REACT_APP_DOMAIN}/Agent3.ashx`, data);
  }
);
