import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "react-phone-input-2/lib/style.css";
import { Provider } from "react-redux";
import "../App.css";
import I18nProvider from "../i18n/i18n";
import store from "../setup/Store";
import AppRoutes from "./routing/AppRoutes";

function App() {
  return (
    <I18nProvider>
      <Provider store={store}>
        <AppRoutes />
      </Provider>
    </I18nProvider>
  );
}

export default App;
