import { createContext } from "react";
import { Route, Routes } from "react-router-dom";
import { Member } from "../../modules/member/components/Member";
import { Status404 } from "../../modules/statusPage/component/Status404";
export const MemberContext = createContext({});

export const MemberWrapper = () => {
  const value = {};

  return (
    <MemberContext.Provider value={value}>
      <Routes>
        <Route path="" element={<Member />} />
        <Route path="*" element={<Status404 />} />
      </Routes>
    </MemberContext.Provider>
  );
};
