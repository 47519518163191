import { reduxBatch } from "@manaflair/redux-batch";
import { configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import { rootReducer } from "./RootReducer";

// Configure the Redux store
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      thunk: true,
    }),
  devTools: process.env.NODE_ENV !== "production",
  enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(reduxBatch),
});

// Define the AppDispatch type
export type AppDispatch = typeof store.dispatch;

// Create and export the persistor
export const persistor = persistStore(store);

export default store;
