import { IGeneralRedux } from "../../../common/models/response";
import { ITransactionReportResponse } from "../response";

export interface ITransactionRecordReduxState extends IGeneralRedux {
  transactionReports: ITransactionReportResponse | null;
}

export const transactionRecordState: ITransactionRecordReduxState = {
  transactionReports: null,
  actionsLoading: false,
  listLoading: false,
  searchLoading: false,
};
