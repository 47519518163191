import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { settingState } from "../models/state/SettingState";
import { changePassword } from "./SettingCRUD";

export const settingSlice = createSlice({
  name: "setting",
  initialState: settingState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        changePassword.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.actionsLoading = false;
        }
      )
      .addCase(changePassword.rejected, (state, action: PayloadAction<any>) => {
        state.actionsLoading = false;
      })
      .addCase(changePassword.pending, (state) => {
        state.actionsLoading = true;
      });
  },
});
