/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { CiLogout, CiSettings } from "react-icons/ci";
import { RxHamburgerMenu } from "react-icons/rx";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { CheckIsNull, toAbsoluteUrl } from "../../../../../utils/CommonHelper";
import { NAVBAR } from "../../../../constant/Navbar";

const LayoutProvider: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = (title?: string) => {
    if (!CheckIsNull(title)) {
      navigate(`/${title}`);
    }
    setIsOpen(!isOpen);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <header>
        <div className="flex items-center justify-between p-3 bg-slate-950">
          <RxHamburgerMenu
            className="cursor-pointer size-5"
            onClick={() => handleToggle()}
          />
          <div className="font-semibold">
            {!CheckIsNull(window.location.pathname.split("/")[1])
              ? intl.formatMessage({
                  id: window.location.pathname.split("/")[1],
                })
              : null}
          </div>
          <div className="size-5" />
        </div>
        <div
          className={`fixed inset-y-0 left-0 z-40 w-64 transform transition-transform bg-gray-800 ${
            isOpen ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          <div className="flex flex-col h-full">
            <div className="p-4 my-4">
              <img src={toAbsoluteUrl("/media/logo.png")} className="w-full" />
            </div>
            <nav className="flex-1 py-4 space-y-2">
              {NAVBAR.map((nav) => (
                <a
                  className={`flex items-center px-4 py-2 rounded font-semibold ${
                    window.location.pathname.includes(nav.title)
                      ? "bg-gray-700 text-white"
                      : "hover:bg-gray-700"
                  }`}
                  onClick={() => handleToggle(nav.title)}
                  key={nav.title}
                >
                  {nav.icon}
                  {intl.formatMessage({ id: nav.title })}
                </a>
              ))}
            </nav>
            <div className="border border-gray-600" />
            <div className="py-2">
              <a
                className={`flex items-center px-4 py-2 rounded font-semibold ${
                  window.location.pathname.includes("setting")
                    ? "bg-gray-700 text-white"
                    : "hover:bg-gray-700"
                }`}
                onClick={() => handleToggle("setting")}
              >
                <CiSettings className="mr-2 size-6" />
                {intl.formatMessage({ id: "setting" })}
              </a>
              <a
                className="flex items-center px-4 py-2 font-semibold rounded hover:bg-gray-700"
                onClick={() =>
                  window.dispatchEvent(new CustomEvent("authEvent"))
                }
              >
                <CiLogout className="mr-2 size-6" />
                {intl.formatMessage({ id: "logout" })}
              </a>
            </div>
          </div>
        </div>
      </header>
      {isOpen && (
        <div
          onClick={() => handleToggle()}
          className="fixed inset-0 bg-black opacity-50"
        />
      )}
      <main className="flex flex-col flex-1 p-4">{children}</main>
      <footer></footer>
    </div>
  );
};

export default LayoutProvider;
