export const Loading = () => (
  <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
    <div className="w-16 h-16 border-4 border-white rounded-full border-t-transparent animate-spin"></div>
  </div>
);

export const OverlayLoading = () => (
  <div className="absolute top-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50 end-0 start-0">
    <div className="w-16 h-16 border-4 border-white rounded-full border-t-transparent animate-spin"></div>
  </div>
);
