import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { transactionRecordState } from "../models/state/TransactionRecordState";
import { getTransactionReport } from "./TransactionRecordCRUD";

export const transactionRecordSlice = createSlice({
  name: "transactionRecord",
  initialState: transactionRecordState,
  reducers: {
    clearTransactionReports: (state) => {
      state.transactionReports = transactionRecordState.transactionReports;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTransactionReport.pending, (state) => {
        state.listLoading = true;
      })
      .addCase(
        getTransactionReport.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.listLoading = false;
          state.transactionReports = action.payload;
        }
      )
      .addCase(
        getTransactionReport.rejected,
        (state, action: PayloadAction<any>) => {
          state.listLoading = false;
          state.transactionReports = transactionRecordState.transactionReports;
        }
      );
  },
});
