import { createContext } from "react";
import { Route, Routes } from "react-router-dom";
import { Agent } from "../../modules/agent/components/Agent";
import { Status404 } from "../../modules/statusPage/component/Status404";
export const AgentContext = createContext({});

export const AgentWrapper = () => {
  const value = {};

  return (
    <AgentContext.Provider value={value}>
      <Routes>
        <Route path="" element={<Agent />} />
        <Route path="*" element={<Status404 />} />
      </Routes>
    </AgentContext.Provider>
  );
};
