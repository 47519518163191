import { FC, useEffect, useState } from "react";

interface AlertPopupProps {
  isVisible: boolean;
  message: string;
}

const AlertPopup: FC<AlertPopupProps> = ({ isVisible, message }) => {
  const [show, setShow] = useState(isVisible);

  useEffect(() => {
    if (isVisible) {
      setShow(true);
      const timer = setTimeout(() => {
        setShow(false);
      }, 1500);

      return () => clearTimeout(timer);
    }
  }, [isVisible]);

  return (
    <div
      className={`fixed bottom-4 left-1/2 transform -translate-x-1/2 p-4 rounded-md bg-slate-800 whitespace-nowrap transition-transform duration-500 ease-in-out ${
        show ? "translate-y-0 opacity-100" : "translate-y-20 opacity-0"
      }`}
      style={{ zIndex: "999999" }}
    >
      {message}
    </div>
  );
};

export default AlertPopup;
