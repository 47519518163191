import { FC } from "react";
import { GrNext, GrPrevious } from "react-icons/gr";

const Pagination: FC<{
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}> = ({ totalPages, currentPage, onPageChange }) => {
  const handlePageChange = (page: number) => {
    if (page !== currentPage) {
      onPageChange(page);
    }
  };

  return (
    <div className="flex items-center max-w-full space-x-2">
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className={`p-2 rounded-lg ${
          currentPage === 1
            ? "bg-gray-700 text-gray-500 cursor-not-allowed"
            : "bg-gray-700 text-gray-300 hover:bg-gray-600"
        }`}
      >
        <GrPrevious />
      </button>
      <div className="flex flex-1 space-x-2 overflow-x-auto">
        {Array.from({ length: totalPages > 0 ? totalPages : 1 }, (_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={`px-3 py-1 rounded-lg ${
              index + 1 === currentPage
                ? "bg-yellow-400 text-slate-900 font-semibold"
                : "bg-gray-700 text-gray-300 hover:bg-gray-600"
            }`}
          >
            {index + 1}
          </button>
        ))}
      </div>
      <button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className={`p-2 rounded-lg ${
          currentPage === totalPages
            ? "bg-gray-700 text-gray-500 cursor-not-allowed"
            : "bg-gray-700 text-gray-300 hover:bg-gray-600"
        }`}
      >
        <GrNext />
      </button>
    </div>
  );
};

export default Pagination;
