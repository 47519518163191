import { Field, Form, Formik, FormikProps } from "formik";
import { useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import Modal from "../../common/components/Modal";
import { Input } from "../../common/components/control/InputField";
import { BaseResponseModel } from "../../common/models/response/ResponseModel";
import * as actions from "../redux/SettingAction";

export const Setting = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isResponseModalOpen, setIsResponseModalOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [modalTitle, setModalTitle] = useState("info");

  const editPasswordFormikRef = useRef<FormikProps<{
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
  }> | null>(null);

  const editPasswordSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "inputIsMandatory" })),
    newPassword: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "inputIsMandatory" })),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("newPassword")],
        intl.formatMessage({ id: "passwordMustMatch" })
      )
      .required(intl.formatMessage({ id: "inputIsMandatory" })),
  });

  const editPassword = (values: {
    oldPassword: string;
    newPassword: string;
    confirmPassword: string;
  }) => {
    dispatch<any>(
      actions.changePassword({
        action: "changepassword",
        oldpassword: values.oldPassword,
        newpassword: values.newPassword,
        repeatpassword: values.confirmPassword,
      })
    )
      .then((res?: BaseResponseModel) => {
        setIsResponseModalOpen(true);
        setResponseMessage(
          res?.code === "0"
            ? intl.formatMessage({ id: "saveSuccessfully" })
            : res?.message
            ? res.message
            : intl.formatMessage({ id: "saveFail" })
        );
        setModalTitle(res?.code === "0" ? "info" : "alert");
        if (res?.code === "0") {
          editPasswordFormikRef.current?.resetForm();
        }
      })
      .catch(() => {
        setIsResponseModalOpen(true);
        setResponseMessage(intl.formatMessage({ id: "saveFail" }));
        setModalTitle("alert");
      });
  };

  return (
    <>
      <Modal
        style={{ zIndex: "999999" }}
        isOpen={isResponseModalOpen}
        onClose={() => {
          setIsResponseModalOpen(false);
        }}
        title={intl.formatMessage({ id: modalTitle })}
      >
        {responseMessage}
      </Modal>
      <Formik
        initialValues={{
          oldPassword: "",
          newPassword: "",
          confirmPassword: "",
        }}
        innerRef={editPasswordFormikRef}
        validationSchema={editPasswordSchema}
        enableReinitialize={true}
        onSubmit={(values) => editPassword(values)}
      >
        {({ values, setFieldValue }) => (
          <Form className="pt-4">
            <div className="mb-3">
              <Field
                name="oldPassword"
                component={Input}
                placeholder={intl.formatMessage({
                  id: "oldPassword",
                })}
                label={intl.formatMessage({
                  id: "oldPassword",
                })}
                autoComplete="off"
                type="password"
              />
            </div>
            <div className="mb-3">
              <Field
                name="newPassword"
                component={Input}
                placeholder={intl.formatMessage({
                  id: "newPassword",
                })}
                label={intl.formatMessage({
                  id: "newPassword",
                })}
                autoComplete="off"
                type="password"
              />
            </div>
            <div className="mb-6">
              <Field
                name="confirmPassword"
                component={Input}
                placeholder={intl.formatMessage({
                  id: "confirmPassword",
                })}
                label={intl.formatMessage({
                  id: "confirmPassword",
                })}
                autoComplete="off"
                type="password"
              />
            </div>
            <button
              className="w-full px-4 py-2 font-semibold bg-yellow-400 rounded text-slate-950 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-yellow-600"
              type="submit"
            >
              {intl.formatMessage({ id: "save" })}
            </button>
          </Form>
        )}
      </Formik>
    </>
  );
};
