import { IGeneralRedux } from "../../../common/models/response";
import { IAgentListResponse, IShareAgentInfoResponse } from "../response";

export interface IAgentReduxState extends IGeneralRedux {
  agentList: IAgentListResponse | null;
  agentInfo: IShareAgentInfoResponse | null;
}

export const agentState: IAgentReduxState = {
  agentList: null,
  agentInfo: null,
  actionsLoading: false,
  listLoading: false,
  searchLoading: false,
};
