import { IGeneralRedux } from "../../../common/models/response";
import { IMemberListResponse, IShareMemberInfoResponse } from "../response";

export interface IMemberReduxState extends IGeneralRedux {
  memberList: IMemberListResponse | null;
  memberInfo: IShareMemberInfoResponse | null;
}

export const memberState: IMemberReduxState = {
  memberList: null,
  memberInfo: null,
  actionsLoading: false,
  listLoading: false,
  searchLoading: false,
};
