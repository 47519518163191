import { FC, useState } from "react";
import { CiShare2 } from "react-icons/ci";
import { HiOutlineClipboardCopy } from "react-icons/hi";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../setup/RootReducer";
import AlertPopup from "../../common/components/AlertPopup";
import Modal from "../../common/components/Modal";
import * as actions from "../redux/AgentAction";

export const AgentInfoModal: FC<{
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({ isModalOpen, setIsModalOpen }) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const { agentInfo } = useSelector((state: RootState) => state.agent);
  const [isAlertVisible, setAlertVisible] = useState(false);

  return (
    <>
      <AlertPopup
        isVisible={isAlertVisible}
        message={intl.formatMessage({ id: "copied" })}
      />
      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          dispatch<any>(actions.clearAgentInfo());
        }}
        title={intl.formatMessage({ id: "agentInfo" })}
      >
        {agentInfo && (
          <>
            <a
              className="block mb-3 font-semibold underline"
              href={agentInfo.weburl}
              target="_blank"
              rel="noreferrer"
            >
              {agentInfo.weburl}
            </a>
            <div className="flex items-end">
              <div className="flex-1">
                <div className="flex items-center mb-1">
                  <div className="text-xs font-semibold text-gray-300 me-1">
                    {intl.formatMessage({ id: "username" })} :
                  </div>
                  <div>{agentInfo.username}</div>
                </div>
                <div className="flex items-center">
                  <div className="text-xs font-semibold text-gray-300 me-1">
                    {intl.formatMessage({ id: "password" })} :
                  </div>
                  <div>{agentInfo.password}</div>
                </div>
              </div>
              <div className="flex items-center">
                <HiOutlineClipboardCopy
                  className="cursor-pointer size-5 me-2"
                  onClick={() =>
                    navigator.clipboard
                      .writeText(
                        `${agentInfo.weburl} \n\nUsername: ${agentInfo.username} \nPassword: ${agentInfo.password}`
                      )
                      .then(() => {
                        setAlertVisible(true);
                        setTimeout(() => {
                          setAlertVisible(false);
                        }, 1500);
                      })
                  }
                />
                <CiShare2
                  className="cursor-pointer size-5"
                  onClick={() => {
                    navigator.share({
                      title: intl.formatMessage({ id: "loginCredential" }),
                      text: `${agentInfo.weburl} \n\nUsername: ${agentInfo.username} \nPassword: ${agentInfo.password}`,
                    });
                  }}
                />
              </div>
            </div>
          </>
        )}
      </Modal>
    </>
  );
};
