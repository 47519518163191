import { AppDispatch } from "../../../../setup/Store";
import { ITransactionReportRequest } from "../models/request";
import * as requestApi from "./TransactionRecordCRUD";
import { transactionRecordSlice } from "./TransactionRecordSlice";

const { actions } = transactionRecordSlice;

export const clearTransactionReports = () => (dispatch: AppDispatch) => {
  dispatch(actions.clearTransactionReports());
};

export const fetchTransactionReport =
  (data: ITransactionReportRequest) => (dispatch: AppDispatch) => {
    dispatch(requestApi.getTransactionReport(data));
  };
