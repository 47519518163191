import { FC } from "react";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const Modal: FC<ModalProps> = ({ isOpen, onClose, title, children, style }) => {
  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={onClose}
      style={style}
    >
      <div
        className="relative w-11/12 max-w-lg text-white rounded-lg shadow-lg bg-slate-900"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="flex items-center px-4 pt-4 pb-2 mb-2 border-b border-gray-700">
          <div className="flex-1 text-lg font-semibold">
            {title && <>{title}</>}
          </div>
          <button onClick={onClose} className="text-gray-300 hover:text-white">
            ✕
          </button>
        </div>
        <div className="px-4 pb-4">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
