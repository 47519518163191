import { createAsyncThunk } from "@reduxjs/toolkit";
import { authPost } from "../../common/redux/ApiClient";
import { IChangePasswordRequest } from "../models/request";

export const changePassword = createAsyncThunk(
  "sample/changepassword",
  async (data: IChangePasswordRequest) => {
    return await authPost(`${process.env.REACT_APP_DOMAIN}/Agent1.ashx`, data);
  }
);
