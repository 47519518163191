import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { agentState } from "../models/state/AgentState";
import {
  agentTopupAgent,
  createNewAgent,
  editAgentNickname,
  getAgentList,
  shareAgentInfo,
} from "./AgentCRUD";

export const agentSlice = createSlice({
  name: "agent",
  initialState: agentState,
  reducers: {
    clearAgentList: (state) => {
      state.agentList = agentState.agentList;
    },
    clearAgentInfo: (state) => {
      state.agentInfo = agentState.agentInfo;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAgentList.pending, (state) => {
        state.listLoading = true;
      })
      .addCase(getAgentList.fulfilled, (state, action: PayloadAction<any>) => {
        state.listLoading = false;
        state.agentList = action.payload;
      })
      .addCase(getAgentList.rejected, (state, action: PayloadAction<any>) => {
        state.listLoading = false;
        state.agentList = agentState.agentList;
      })
      .addCase(shareAgentInfo.pending, (state) => {
        state.actionsLoading = true;
      })
      .addCase(
        shareAgentInfo.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.actionsLoading = false;
          state.agentInfo = action.payload;
        }
      )
      .addCase(shareAgentInfo.rejected, (state, action: PayloadAction<any>) => {
        state.actionsLoading = false;
        state.agentInfo = agentState.agentInfo;
      })
      .addCase(createNewAgent.pending, (state) => {
        state.actionsLoading = true;
      })
      .addCase(
        createNewAgent.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.actionsLoading = false;
        }
      )
      .addCase(createNewAgent.rejected, (state, action: PayloadAction<any>) => {
        state.actionsLoading = false;
      })
      .addCase(editAgentNickname.pending, (state) => {
        state.actionsLoading = true;
      })
      .addCase(
        editAgentNickname.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.actionsLoading = false;
        }
      )
      .addCase(
        editAgentNickname.rejected,
        (state, action: PayloadAction<any>) => {
          state.actionsLoading = false;
        }
      )
      .addCase(agentTopupAgent.pending, (state) => {
        state.actionsLoading = true;
      })
      .addCase(
        agentTopupAgent.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.actionsLoading = false;
        }
      )
      .addCase(
        agentTopupAgent.rejected,
        (state, action: PayloadAction<any>) => {
          state.actionsLoading = false;
        }
      );
  },
});
