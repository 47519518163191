import { createContext } from "react";
import { Route, Routes } from "react-router-dom";
import { Home } from "../../modules/home/components/Home";
import { Status404 } from "../../modules/statusPage/component/Status404";
export const HomeContext = createContext({});

export const HomeWrapper = () => {
  const value = {};

  return (
    <HomeContext.Provider value={value}>
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="*" element={<Status404 />} />
      </Routes>
    </HomeContext.Provider>
  );
};
