import { createAsyncThunk } from "@reduxjs/toolkit";
import { authPost, postData } from "../../common/redux/ApiClient";
import {
  IAgentEmailOTPRequest,
  IAgentLoginRequest,
  IAgentPhoneOTPRequest,
  IAgentSubmitOTPRequest,
} from "../models/request";

export const agentLogin = createAsyncThunk(
  "sample/agentLogin",
  async (data: IAgentLoginRequest) => {
    return await postData(`${process.env.REACT_APP_DOMAIN}/Agent1.ashx`, data);
  }
);

export const agentRequestPhoneOTP = createAsyncThunk(
  "sample/agentactiverequestotp_phone",
  async (data: IAgentPhoneOTPRequest) => {
    return await authPost(`${process.env.REACT_APP_DOMAIN}/Agent1.ashx`, data);
  }
);

export const agentRequestEmailOTP = createAsyncThunk(
  "sample/agentactiverequestotp_email",
  async (data: IAgentEmailOTPRequest) => {
    return await authPost(`${process.env.REACT_APP_DOMAIN}/Agent1.ashx`, data);
  }
);

export const agentSubmitOTP = createAsyncThunk(
  "sample/agentsubmitotp",
  async (data: IAgentSubmitOTPRequest) => {
    return await authPost(`${process.env.REACT_APP_DOMAIN}/Agent1.ashx`, data);
  }
);
