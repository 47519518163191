import { AppDispatch } from "../../../../setup/Store";
import {
  IAgentCreateRequest,
  IAgentListRequest,
  IAgentNicknameUpdateRequest,
  IAgentTopupRequest,
  IShareAgentInfoRequest,
} from "../models/request";
import * as requestApi from "./AgentCRUD";
import { agentSlice } from "./AgentSlice";

const { actions } = agentSlice;

export const clearAgentList = () => (dispatch: AppDispatch) => {
  dispatch(actions.clearAgentList());
};

export const fetchAgentList =
  (data: IAgentListRequest) => (dispatch: AppDispatch) => {
    dispatch(requestApi.getAgentList(data));
  };

export const clearAgentInfo = () => (dispatch: AppDispatch) => {
  dispatch(actions.clearAgentInfo());
};

export const shareAgentInfo =
  (data: IShareAgentInfoRequest) => (dispatch: AppDispatch) => {
    dispatch(requestApi.shareAgentInfo(data));
  };

export const createNewAgent =
  (data: IAgentCreateRequest) => (dispatch: AppDispatch) => {
    return dispatch(requestApi.createNewAgent(data)).then(
      (res) => res?.payload
    );
  };

export const editAgentNickname =
  (data: IAgentNicknameUpdateRequest) => (dispatch: AppDispatch) => {
    return dispatch(requestApi.editAgentNickname(data)).then(
      (res) => res?.payload
    );
  };

export const agentTopupAgent =
  (data: IAgentTopupRequest) => (dispatch: AppDispatch) => {
    return dispatch(requestApi.agentTopupAgent(data)).then(
      (res) => res?.payload
    );
  };
