import { AppDispatch } from "../../../../setup/Store";
import {
  IMemberCreateRequest,
  IMemberListRequest,
  IMemberNicknameUpdateRequest,
  IShareMemberInfoRequest,
} from "../models/request";
import { IMemberTopupRequest } from "../models/request/MemberTopupRequestModel";
import * as requestApi from "./MemberCRUD";
import { memberSlice } from "./MemberSlice";

const { actions } = memberSlice;

export const clearMemberList = () => (dispatch: AppDispatch) => {
  dispatch(actions.clearMemberList());
};

export const fetchMemberList =
  (data: IMemberListRequest) => (dispatch: AppDispatch) => {
    dispatch(requestApi.getMemberList(data));
  };

export const clearMemberInfo = () => (dispatch: AppDispatch) => {
  dispatch(actions.clearMemberInfo());
};

export const shareMemberInfo =
  (data: IShareMemberInfoRequest) => (dispatch: AppDispatch) => {
    dispatch(requestApi.shareMemberInfo(data));
  };

export const createNewMember =
  (data: IMemberCreateRequest) => (dispatch: AppDispatch) => {
    return dispatch(requestApi.createNewMember(data)).then(
      (res) => res?.payload
    );
  };

export const editMemberNickname =
  (data: IMemberNicknameUpdateRequest) => (dispatch: AppDispatch) => {
    return dispatch(requestApi.editMemberNickname(data)).then(
      (res) => res?.payload
    );
  };

export const topupMember =
  (data: IMemberTopupRequest) => (dispatch: AppDispatch) => {
    return dispatch(requestApi.topupMember(data)).then((res) => res?.payload);
  };
