import { AppDispatch } from "../../../../setup/Store";
import { IChangePasswordRequest } from "../models/request";
import * as requestApi from "./SettingCRUD";

export const changePassword =
  (data: IChangePasswordRequest) => (dispatch: AppDispatch) => {
    return dispatch(requestApi.changePassword(data)).then(
      (res) => res?.payload
    );
  };
