import CryptoJS from "crypto-js";
import { Field, Form, Formik, FormikProps } from "formik";
import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import OTPInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { CheckIsNull, toAbsoluteUrl } from "../../../../utils/CommonHelper";
import {
  ErrorContainer,
  Input,
  PhoneInputField,
} from "../../common/components/control/InputField";
import { Loading, OverlayLoading } from "../../common/components/Loading";
import { AUTH_LOCAL_STORAGE_KEY } from "../../common/redux/ApiClient";
import { RequestOTPSelectionEnum } from "../enum/RequestOTPSelectionEnum";
import { StepEnum } from "../enum/StepEnum";
import { IAgentLoginResponse } from "../models/response";
import * as actions from "../redux/AuthAction";

export const Auth = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const [isLoginError, setIsLoginError] = useState(false);
  const [step, setStep] = useState(StepEnum.Auth);
  const [requestOTPSelection, setRequestOTPSelection] = useState(
    RequestOTPSelectionEnum.Phone
  );
  const [selectionValue, setSelectionValue] = useState<string | undefined>();

  const formikRef = useRef<FormikProps<{ value: string }> | null>(null);
  const loginFormikRef = useRef<FormikProps<{
    username: string;
    password: string;
  }> | null>(null);

  const [phoneNoErrorDescription, setPhoneNoErrorDescription] = useState("");
  const [phoneCode, setPhoneCode] = useState("my");
  const [isShowPhoneNoError, setIsShowPhoneNoError] = useState(false);

  const [otp, setOtp] = useState("");
  const [isShowOtpError, setIsShowOTPError] = useState(false);
  const [otpErrorDescription, setOtpErrorDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const { credential } = useParams();

  useEffect(() => {
    if (step === StepEnum.Auth && !CheckIsNull(credential)) {
      try {
        const decodeCredential = JSON.parse(
          CryptoJS.AES.decrypt(
            atob(decodeURIComponent(credential!)) ?? "",
            "lott4896#!@lott4896"
          )?.toString(CryptoJS.enc.Utf8) ?? {}
        );
        if (decodeCredential?.username && decodeCredential?.password) {
          agentLogin(
            decodeCredential.username as string,
            decodeCredential.password as string
          );
        }
      } catch {
        navigate("/");
      }
    }
  }, [step, credential]);

  const agentLogin = (username: string, password: string) => {
    setIsLoading(true);
    dispatch<any>(
      actions.agentLogin({
        action: "agentlogin",
        username,
        password,
        fcmnotificationid: "",
        ipaddress: "",
      })
    )
      .then((res?: IAgentLoginResponse) => {
        setIsLoading(false);
        if (res?.code === "0") {
          window.dispatchEvent(
            new CustomEvent("authEvent", {
              detail: res,
            })
          );
          setStep(StepEnum.AuthOTPSelection);
        } else {
          if (!CheckIsNull(credential)) setStep(StepEnum.AuthFail);
          else {
            setIsLoginError(true);
            loginFormikRef.current?.setFieldValue("password", "");
            loginFormikRef.current?.setFieldTouched("password", false);
          }
        }
      })
      .catch(() => {
        setIsLoading(false);
        if (!CheckIsNull(credential)) setStep(StepEnum.AuthFail);
        else {
          setIsLoginError(true);
          loginFormikRef.current?.setFieldValue("password", "");
          loginFormikRef.current?.setFieldTouched("password", false);
        }
      });
  };

  const requestOTP = (value: string) => {
    setIsLoading(true);
    const dispatchAction =
      requestOTPSelection === RequestOTPSelectionEnum.Email
        ? actions.agentRequestEmailOTP({
            action: "agentactiverequestotp_email",
            email: value,
          })
        : actions.agentRequestPhoneOTP({
            action: "agentactiverequestotp_phone",
            telno: value,
          });

    dispatch<any>(dispatchAction)
      .then((res?: IAgentLoginResponse) => {
        setIsLoading(false);
        if (res?.code === "0") {
          setStep(StepEnum.AuthOTPValidation);
        }
      })
      .catch(() => setIsLoading(false));
  };

  const verifyOTP = (otp: string) => {
    if (localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)) {
      setIsLoading(true);
      dispatch<any>(
        actions.agentSubmitOTP({
          action: "agentsubmitotp",
          otpcode: otp,
          mediatype: RequestOTPSelectionEnum.Email ? "email" : "telno",
        })
      )
        .then((res?: IAgentLoginResponse) => {
          setIsLoading(false);
          setOtp("");
          if (res?.code === "0") {
            window.dispatchEvent(
              new CustomEvent("authEvent", {
                detail: {
                  ...JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)!),
                  toactive: "false",
                },
              })
            );
          } else {
            setIsShowOTPError(true);
            setOtpErrorDescription(res?.message ?? "");
          }
        })
        .catch(() => setIsLoading(false));
    }
  };

  const handleSelectionClick = (
    requestOTPSelection: RequestOTPSelectionEnum
  ) => {
    formikRef.current?.resetForm();
    setSelectionValue(undefined);
    setRequestOTPSelection(requestOTPSelection);
    setIsShowPhoneNoError(false);
    setPhoneNoErrorDescription("");
    setPhoneCode("my");
  };

  const requestOtpSchema = Yup.object().shape({
    value: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "inputIsMandatory" })),
  });

  const loginSchema = Yup.object().shape({
    username: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "inputIsMandatory" })),
    password: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "inputIsMandatory" })),
  });

  return (
    <>
      {isLoading && <OverlayLoading />}
      {step === StepEnum.Auth ? (
        CheckIsNull(credential) ? (
          <div className="flex flex-col items-stretch justify-center min-h-screen pb-10">
            <div className="flex items-center justify-center">
              <img
                src={toAbsoluteUrl("/media/logo.png")}
                className="mb-4 w-60"
              />
            </div>
            <Formik
              initialValues={{
                username: "",
                password: "",
              }}
              innerRef={loginFormikRef}
              validationSchema={loginSchema}
              enableReinitialize={true}
              onSubmit={(values) => {
                agentLogin(values.username, values.password);
              }}
            >
              {({ values, setFieldValue }) => (
                <Form className="pt-4">
                  <div className="mb-3">
                    <Field
                      name="username"
                      component={Input}
                      placeholder={intl.formatMessage({
                        id: "username",
                      })}
                      label={intl.formatMessage({
                        id: "username",
                      })}
                      autoComplete="off"
                      type="text"
                    />
                  </div>
                  <div className="mb-6">
                    <Field
                      name="password"
                      component={Input}
                      placeholder={intl.formatMessage({
                        id: "password",
                      })}
                      label={intl.formatMessage({
                        id: "password",
                      })}
                      autoComplete="off"
                      type="password"
                    />
                    <ErrorContainer
                      errorMsg={intl.formatMessage({ id: "invalidCredential" })}
                      isShow={isLoginError}
                    />
                  </div>
                  <button
                    className="w-full px-4 py-2 font-semibold bg-yellow-400 rounded text-slate-950 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-yellow-600"
                    type="submit"
                  >
                    {intl.formatMessage({ id: "login" })}
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        ) : (
          <Loading />
        )
      ) : step === StepEnum.AuthFail ? (
        <div className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
          <div className="font-semibold text-center text-red-300">
            {intl.formatMessage({ id: "authFail" })}
          </div>
        </div>
      ) : step === StepEnum.AuthOTPSelection ? (
        <div className="flex flex-col justify-center min-h-screen pb-10">
          <div className="text-xl font-semibold text-center">
            {intl.formatMessage({ id: "verification" })}
          </div>
          <div className="pt-6">
            <div className="flex border border-gray-300 rounded">
              <div
                className={`flex-1 p-2 text-center border-r border-gray-300 cursor-pointer font-semibold ${
                  requestOTPSelection === RequestOTPSelectionEnum.Phone
                    ? "bg-yellow-400 text-black"
                    : ""
                }`}
                onClick={() =>
                  handleSelectionClick(RequestOTPSelectionEnum.Phone)
                }
              >
                {intl.formatMessage({ id: "phone" })}
              </div>
              <div
                className={`flex-1 p-2 text-center cursor-pointer font-semibold ${
                  requestOTPSelection === RequestOTPSelectionEnum.Email
                    ? "bg-yellow-400 text-black"
                    : ""
                }`}
                onClick={() =>
                  handleSelectionClick(RequestOTPSelectionEnum.Email)
                }
              >
                {intl.formatMessage({ id: "email" })}
              </div>
            </div>
          </div>
          <Formik
            initialValues={{
              value: "",
            }}
            innerRef={formikRef}
            validationSchema={requestOtpSchema}
            enableReinitialize={true}
            onSubmit={(values) => {
              setSelectionValue(values.value);
              requestOTP(values.value);
            }}
          >
            {({ values, setFieldValue }) => (
              <Form className="pt-4">
                <div className="mb-10">
                  {requestOTPSelection === RequestOTPSelectionEnum.Phone ? (
                    <PhoneInputField
                      values={values}
                      setFieldValue={setFieldValue}
                      setIsShowPhoneNoError={setIsShowPhoneNoError}
                      isShowPhoneNoError={isShowPhoneNoError}
                      phoneNoErrorDescription={phoneNoErrorDescription}
                      setPhoneNoErrorDescription={setPhoneNoErrorDescription}
                      name="value"
                      country={phoneCode}
                    />
                  ) : (
                    <Field
                      name="value"
                      component={Input}
                      placeholder={intl.formatMessage({
                        id: "email",
                      })}
                      label={intl.formatMessage({
                        id: "email",
                      })}
                      autoComplete="off"
                      type="text"
                    />
                  )}
                </div>
                <button
                  onClick={() => {
                    if (CheckIsNull(values.value)) {
                      setIsShowPhoneNoError(true);
                      setPhoneNoErrorDescription(
                        intl.formatMessage({ id: "inputIsMandatory" })
                      );
                    }
                  }}
                  className="w-full px-4 py-2 font-semibold text-white bg-gray-800 rounded hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600"
                  type="submit"
                >
                  {intl.formatMessage({ id: "requestOTP" })}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      ) : step === StepEnum.AuthOTPValidation ? (
        <div className="flex flex-col justify-between min-h-screen pb-10">
          <div
            className="flex items-center justify-between text-xl font-semibold"
            onClick={() => setStep(StepEnum.AuthOTPSelection)}
          >
            <i className="cursor-pointer fa fa-arrow-left" />
            <div>{intl.formatMessage({ id: "verification" })}</div>
            <div />
          </div>
          <div>
            <div className="my-4 text-center">
              {intl.formatMessage(
                {
                  id:
                    requestOTPSelection === RequestOTPSelectionEnum.Phone
                      ? "otpHasSendedPhone"
                      : "otpHasSendedEmail",
                },
                { n: selectionValue }
              )}
            </div>
            <OTPInput
              value={otp}
              onChange={setOtp}
              numInputs={5}
              containerStyle="justify-center"
              shouldAutoFocus={true}
              renderSeparator={<div className="mx-2 mb-3"></div>}
              renderInput={(props) => (
                <input
                  {...props}
                  className="py-2 text-center text-white rounded otp-field bg-blue-950"
                />
              )}
            />
            <div className="flex justify-center mt-2">
              <ErrorContainer
                isShow={isShowOtpError}
                errorMsg={otpErrorDescription}
              />
            </div>
            <button
              onClick={() => {
                if (!CheckIsNull(otp)) {
                  setIsShowOTPError(false);
                  verifyOTP(otp);
                } else {
                  setIsShowOTPError(true);
                  setOtpErrorDescription(
                    intl.formatMessage({ id: "otpRequired" })
                  );
                }
              }}
              className="w-full px-4 py-2 mt-6 font-semibold text-white bg-gray-800 rounded hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600"
              type="button"
            >
              {intl.formatMessage({ id: "submit" })}
            </button>
          </div>
          <div />
        </div>
      ) : null}
    </>
  );
};
