import { AppDispatch } from "../../../../setup/Store";
import {
  IAgentEmailOTPRequest,
  IAgentLoginRequest,
  IAgentPhoneOTPRequest,
  IAgentSubmitOTPRequest,
} from "../models/request";
import * as requestApi from "./AuthCRUD";
import { authSlice } from "./AuthSlice";

const { actions } = authSlice;

export const agentLogin =
  (data: IAgentLoginRequest) => (dispatch: AppDispatch) => {
    return dispatch(requestApi.agentLogin(data)).then((res) => res?.payload);
  };

export const agentRequestEmailOTP =
  (data: IAgentEmailOTPRequest) => (dispatch: AppDispatch) => {
    return dispatch(requestApi.agentRequestEmailOTP(data)).then(
      (res) => res?.payload
    );
  };

export const agentRequestPhoneOTP =
  (data: IAgentPhoneOTPRequest) => (dispatch: AppDispatch) => {
    return dispatch(requestApi.agentRequestPhoneOTP(data)).then(
      (res) => res?.payload
    );
  };

export const agentSubmitOTP =
  (data: IAgentSubmitOTPRequest) => (dispatch: AppDispatch) => {
    return dispatch(requestApi.agentSubmitOTP(data)).then(
      (res) => res?.payload
    );
  };
