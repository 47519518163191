import { combineReducers } from "redux";
import { agentSlice } from "../app/modules/agent/redux/AgentSlice";
import { homeSlice } from "../app/modules/home/redux/HomeSlice";
import { memberSlice } from "../app/modules/member/redux/MemberSlice";
import { reportSlice } from "../app/modules/report/redux/ReportSlice";
import { sampleSlice } from "../app/modules/sample/redux/SampleSlice";
import { settingSlice } from "../app/modules/setting/redux/SettingSlice";
import { transactionRecordSlice } from "../app/modules/transactionRecord/redux/TransactionRecordSlice";

export const rootReducer = combineReducers({
  // get from slice file
  home: homeSlice.reducer,
  agent: agentSlice.reducer,
  member: memberSlice.reducer,
  report: reportSlice.reducer,
  transactionRecord: transactionRecordSlice.reducer,
  setting: settingSlice.reducer,
  sample: sampleSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
