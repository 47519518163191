import { addDays, format } from "date-fns";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../setup/RootReducer";
import { DateRangeField } from "../../common/components/control/InputField";
import { Loading } from "../../common/components/Loading";
import Pagination from "../../common/components/Pagination";
import * as actions from "../redux/TransactionRecordAction";

export const TransactionRecord = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(addDays(new Date(), 7));
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { transactionReports } = useSelector(
    (state: RootState) => state.transactionRecord
  );

  const handlePageChange = (page: number) => {
    fetchTransactionReport(page);
    setCurrentPage(page);
  };

  const fetchTransactionReport = (pagenum?: number) => {
    dispatch<any>(
      actions.fetchTransactionReport({
        action: "transactionreport",
        fromdate: format(startDate, "yyyy-MM-dd"),
        todate: format(endDate, "yyyy-MM-dd"),
        pagenum: ((pagenum ?? currentPage) - 1).toString(),
      })
    );
  };

  useEffect(() => {
    fetchTransactionReport();

    return () => {
      dispatch<any>(actions.clearTransactionReports());
    };
  }, []);

  return (
    <>
      <div className="flex-1">
        <div className="relative mb-4">
          <div className="flex rounded bg-blue-950">
            <div className="flex-grow py-2 rounded outline-none cursor-pointer ps-2 bg-blue-950 focus:outline-none">
              <DateRangeField
                onDateChange={(startDate: Date, endDate: Date) => {
                  setStartDate(startDate);
                  setEndDate(endDate);
                }}
              />
            </div>
            <button
              type="button"
              className="px-2 bg-blue-900 rounded"
              onClick={() => handlePageChange(1)}
            >
              {intl.formatMessage({ id: "search" })}
            </button>
          </div>
        </div>
        {transactionReports ? (
          transactionReports.tranasctionReports?.length > 0 ? (
            <div className="overflow-x-auto">
              <table className="min-w-full">
                <thead>
                  <tr>
                    <th className="p-2 text-sm font-semibold tracking-wider text-left text-gray-200 uppercase border-b-2 border-gray-700">
                      {intl.formatMessage({ id: "dateTime" })}
                    </th>
                    <th className="p-2 text-sm font-semibold tracking-wider text-left text-gray-200 uppercase border-b-2 border-gray-700">
                      {intl.formatMessage({ id: "action" })}
                    </th>
                    <th className="p-2 text-sm font-semibold tracking-wider text-left text-gray-200 uppercase border-b-2 border-gray-700 whitespace-nowrap">
                      {intl.formatMessage({ id: "remark" })}
                    </th>
                    <th className="p-2 text-sm font-semibold tracking-wider text-right text-gray-200 uppercase border-b-2 border-gray-700">
                      {intl.formatMessage({ id: "cash" })}
                    </th>
                    <th className="p-2 text-sm font-semibold tracking-wider text-right text-gray-200 uppercase border-b-2 border-gray-700">
                      {intl.formatMessage({ id: "amount" })}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {transactionReports.tranasctionReports.map(
                    (tranasctionReport) => (
                      <tr key={tranasctionReport.itemid}>
                        <td className="p-2 text-sm border-b border-gray-700">
                          {tranasctionReport.transdate}{" "}
                          {tranasctionReport.transtime}
                        </td>
                        <td className="p-2 text-sm border-b border-gray-700 whitespace-nowrap">
                          {tranasctionReport.action}
                        </td>
                        <td className="p-2 text-sm border-b border-gray-700">
                          {tranasctionReport.remark}
                        </td>
                        <td
                          className={`p-2 text-sm text-right border-b border-gray-700 ${
                            parseFloat(tranasctionReport.cash ?? "0") < 0
                              ? "text-red-500"
                              : ""
                          }`}
                        >
                          {tranasctionReport.cash}
                        </td>
                        <td className="p-2 text-sm text-right border-b border-gray-700">
                          {tranasctionReport.amount}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="text-center">
              {intl.formatMessage({ id: "noRecordFound" })}
            </div>
          )
        ) : (
          <Loading />
        )}
      </div>
      <div className="flex items-center justify-center py-4">
        <Pagination
          totalPages={parseInt(transactionReports?.totalpage ?? "1")}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  );
};
