import { useEffect, useState } from "react";
import { CiEdit, CiShare2 } from "react-icons/ci";
import { IoMdPersonAdd } from "react-icons/io";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../setup/RootReducer";
import { Loading, OverlayLoading } from "../../common/components/Loading";
import Pagination from "../../common/components/Pagination";
import { IAgentLists } from "../models/response";
import * as actions from "../redux/AgentAction";
import { AgentCreateModal } from "./AgentCreateModal";
import { AgentEditModal } from "./AgentEditModal";
import { AgentInfoModal } from "./AgentInfoModal";

export const Agent = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCreateAgent, setIsCreateAgent] = useState(false);
  const [agentData, setAgentData] = useState<IAgentLists | undefined>();

  const { agentList, actionsLoading, agentInfo } = useSelector(
    (state: RootState) => state.agent
  );

  const handlePageChange = (page: number) => {
    fetchAgents(searchValue, page);
    setCurrentPage(page);
  };

  const fetchAgents = (searchValue: string, pagenum: number) => {
    dispatch<any>(
      actions.fetchAgentList({
        action: "agentlist",
        searchvalue: searchValue,
        pagenum: (pagenum - 1).toString(),
      })
    );
  };

  const shareAgentInfo = (agentId: string) => {
    dispatch<any>(
      actions.shareAgentInfo({
        action: "shareagentinfo",
        selagentid: agentId,
      })
    );
  };

  useEffect(() => {
    fetchAgents(searchValue, currentPage);

    return () => {
      dispatch<any>(actions.clearAgentList());
      dispatch<any>(actions.clearAgentInfo());
    };
  }, []);

  useEffect(() => {
    if (agentInfo) {
      setIsModalOpen(true);
    }
  }, [agentInfo]);

  return (
    <>
      {actionsLoading && <OverlayLoading />}
      <AgentInfoModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <AgentCreateModal
        isModalOpen={isCreateAgent}
        setIsModalOpen={setIsCreateAgent}
        fetchAgents={() => fetchAgents(searchValue, currentPage)}
      />
      <AgentEditModal
        setAgentData={setAgentData}
        agentData={agentData}
        fetchAgents={() => fetchAgents(searchValue, currentPage)}
      />
      <div className="flex-1">
        <div className="flex mb-4">
          <div className="flex flex-grow rounded bg-blue-950">
            <input
              type="text"
              placeholder={intl.formatMessage({ id: "search" })}
              className="flex-grow py-2 rounded outline-none ps-2 bg-blue-950 focus:outline-none"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <button
              type="button"
              className="px-2 bg-blue-900 rounded"
              onClick={() => handlePageChange(1)}
            >
              {intl.formatMessage({ id: "search" })}
            </button>
          </div>
          <button
            type="button"
            className="px-2 font-semibold bg-yellow-400 rounded ms-2 whitespace-nowrap text-slate-900"
            onClick={() => setIsCreateAgent(true)}
          >
            <IoMdPersonAdd className="inline-block size-5" />
          </button>
        </div>
        {agentList ? (
          agentList.agentLists?.length > 0 ? (
            <div className="flex flex-col items-stretch space-y-2 overflow-y-auto text-sm">
              {agentList.agentLists.map((agent) => (
                <div
                  className="max-w-sm p-2 overflow-hidden rounded shadow-lg bg-slate-800"
                  key={agent.agentid}
                >
                  <div className="flex items-center justify-between mb-3">
                    <div className="flex items-center">
                      <div className="text-base font-semibold">
                        {agent.username}
                      </div>
                      <span
                        className={`ms-2 inline-block px-1.5 py-0.5 rounded text-xs font-medium ${
                          agent.status === "active"
                            ? "bg-green-200 text-green-700"
                            : "bg-gray-200 text-gray-600"
                        }`}
                      >
                        {intl.formatMessage({
                          id: agent.status === "active" ? "active" : "inactive",
                        })}
                      </span>
                    </div>
                    <div className="font-semibold">$ {agent.balance}</div>
                  </div>
                  <div className="flex items-end">
                    <div className="flex-1">
                      <div className="flex items-center mb-3">
                        <div className="text-xs font-semibold text-gray-300 me-1">
                          {intl.formatMessage({ id: "nickname" })} :
                        </div>
                        <div>{agent.nickname}</div>
                      </div>
                      <div className="flex items-center space-x-2">
                        <div className="flex items-center">
                          <div className="text-xs font-semibold text-gray-300 me-1">
                            {intl.formatMessage({ id: "agent" })} :
                          </div>
                          <div>
                            {agent.agentcount}/{agent.agentgroupcount}
                          </div>
                        </div>
                        <div className="flex items-center">
                          <div className="text-xs font-semibold text-gray-300 me-1">
                            {intl.formatMessage({ id: "member" })} :
                          </div>
                          <div>
                            {agent.membercount}/{agent.membergroupcount}
                          </div>
                        </div>
                        <div className="flex items-center">
                          <div className="text-xs font-semibold text-gray-300 me-1">
                            {intl.formatMessage({ id: "share" })} :
                          </div>
                          <div>{agent.share}%</div>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-end">
                      {agent.shareable === "true" && (
                        <CiShare2
                          className="mr-3 cursor-pointer size-6"
                          onClick={() => shareAgentInfo(agent.agentid)}
                        />
                      )}
                      <CiEdit
                        className="cursor-pointer size-6"
                        onClick={() => setAgentData(agent)}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center">
              {intl.formatMessage({ id: "noRecordFound" })}
            </div>
          )
        ) : (
          <Loading />
        )}
      </div>
      <div className="flex items-center justify-center py-4">
        <Pagination
          totalPages={parseInt(agentList?.totalpage ?? "1")}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  );
};
