import { IGeneralRedux } from "../../../common/models/response";
import { ISampleResponse } from "../response";

export interface ISampleReduxState extends IGeneralRedux {
  marcasList: Array<ISampleResponse> | null;
}

export const sampleState: ISampleReduxState = {
  marcasList: null,
  actionsLoading: false,
  listLoading: false,
  searchLoading: false,
};
