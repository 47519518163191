import { Field, Form, Formik, FormikProps } from "formik";
import { FC, ReactNode, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { EditOptionEnum } from "../../../enumeration/EditOptionEnum";
import { Input } from "../../common/components/control/InputField";
import Modal from "../../common/components/Modal";
import { BaseResponseModel } from "../../common/models/response/ResponseModel";
import { IMemberLists, IMemberTopupResponse } from "../models/response";
import * as actions from "../redux/MemberAction";

export const MemberEditModal: FC<{
  setMemberData: React.Dispatch<React.SetStateAction<IMemberLists | undefined>>;
  fetchMembers: () => void;
  memberData?: IMemberLists;
}> = ({ setMemberData, fetchMembers, memberData }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isResponseModalOpen, setIsResponseModalOpen] = useState(false);
  const [responseMessage, setResponseMessage] = useState<string | ReactNode>(
    ""
  );
  const [modalTitle, setModalTitle] = useState("info");
  const [memberEditOption, setMemberEditOption] = useState(
    EditOptionEnum.Transaction
  );

  const nicknameEditFormikRef = useRef<FormikProps<{
    nickname: string;
  }> | null>(null);

  const topupFormikRef = useRef<FormikProps<{
    amount: string;
  }> | null>(null);

  const nicknameEditSchema = Yup.object().shape({
    nickname: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "inputIsMandatory" })),
  });

  const topupSchema = Yup.object().shape({
    amount: Yup.string()
      .nullable()
      .required(intl.formatMessage({ id: "inputIsMandatory" })),
  });

  const handleSelectionClick = (memberEditOption: EditOptionEnum) => {
    setMemberEditOption(memberEditOption);
  };

  const editMemberNickname = (values: { nickname: string }) => {
    if (memberData)
      dispatch<any>(
        actions.editMemberNickname({
          action: "editmembernickname",
          nickname: values.nickname,
          selmemberid: memberData.memberid,
        })
      )
        .then((res?: BaseResponseModel) => {
          setIsResponseModalOpen(true);
          setResponseMessage(
            res?.code === "0"
              ? intl.formatMessage({ id: "updateSuccessfully" })
              : res?.message
              ? res.message
              : intl.formatMessage({ id: "updateFail" })
          );
          setModalTitle(res?.code === "0" ? "info" : "alert");
          if (res?.code === "0") {
            nicknameEditFormikRef.current?.resetForm();
            setMemberData(undefined);
            fetchMembers();
          }
        })
        .catch(() => {
          setIsResponseModalOpen(true);
          setResponseMessage(intl.formatMessage({ id: "updateFail" }));
          setModalTitle("alert");
        });
  };

  const editMemberAmount = (values: { amount: string }) => {
    if (memberData)
      dispatch<any>(
        actions.topupMember({
          action: "topupmember",
          amount: values.amount,
          selmemberid: memberData.memberid,
        })
      )
        .then((res?: IMemberTopupResponse) => {
          setIsResponseModalOpen(true);
          setResponseMessage(
            res?.code === "0" ? (
              <>
                <div className="mb-3">
                  {intl.formatMessage({ id: "topupSuccessfully" })}
                </div>
                <div>
                  {intl.formatMessage({ id: "remainingBalance" })} :{" "}
                  {res?.balance ?? "0.00"}
                </div>
              </>
            ) : res?.message ? (
              res.message
            ) : (
              intl.formatMessage({ id: "topupFail" })
            )
          );
          setModalTitle(res?.code === "0" ? "info" : "alert");
          if (res?.code === "0") {
            topupFormikRef.current?.resetForm();
            setMemberData(undefined);
            fetchMembers();
          }
        })
        .catch(() => {
          setIsResponseModalOpen(true);
          setResponseMessage(intl.formatMessage({ id: "topupFail" }));
          setModalTitle("alert");
        });
  };

  return (
    <>
      <Modal
        style={{ zIndex: "999999" }}
        isOpen={isResponseModalOpen}
        onClose={() => {
          setIsResponseModalOpen(false);
          setMemberEditOption(EditOptionEnum.Transaction);
        }}
        title={intl.formatMessage({ id: modalTitle })}
      >
        {responseMessage}
      </Modal>
      <Modal
        isOpen={Boolean(memberData)}
        onClose={() => {
          setMemberData(undefined);
        }}
        title={intl.formatMessage({ id: "editMember" })}
      >
        <div className="flex border border-gray-300 rounded">
          <div
            className={`flex-1 font-semibold p-2 text-center border-r border-gray-300 cursor-pointer ${
              memberEditOption === EditOptionEnum.Transaction
                ? "bg-yellow-400 text-black"
                : ""
            }`}
            onClick={() => handleSelectionClick(EditOptionEnum.Transaction)}
          >
            {intl.formatMessage({ id: "topup" })}
          </div>
          <div
            className={`flex-1 p-2 text-center cursor-pointer font-semibold ${
              memberEditOption === EditOptionEnum.Nickname
                ? "bg-yellow-400 text-black"
                : ""
            }`}
            onClick={() => handleSelectionClick(EditOptionEnum.Nickname)}
          >
            {intl.formatMessage({ id: "info" })}
          </div>
        </div>
        {memberEditOption === EditOptionEnum.Transaction ? (
          <Formik
            initialValues={{
              amount: "",
            }}
            innerRef={topupFormikRef}
            validationSchema={topupSchema}
            enableReinitialize={true}
            onSubmit={(values) => editMemberAmount(values)}
          >
            {({ values, setFieldValue }) => (
              <Form className="pt-4">
                <div className="mb-6">
                  <Field
                    name="amount"
                    component={Input}
                    placeholder={intl.formatMessage({
                      id: "amount",
                    })}
                    label={intl.formatMessage({
                      id: "amount",
                    })}
                    autoComplete="off"
                    onPaste={(e: any) => {
                      e.preventDefault();
                      const pastedText = e.clipboardData
                        .getData("text")
                        ?.replace(/\D/g, "");
                      setFieldValue("share", pastedText);
                    }}
                    type="numberString"
                  />
                </div>
                <button
                  className="w-full px-4 py-2 font-semibold bg-yellow-400 rounded text-slate-950 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-yellow-600"
                  type="submit"
                >
                  {intl.formatMessage({ id: "topup" })}
                </button>
              </Form>
            )}
          </Formik>
        ) : (
          <Formik
            initialValues={{
              nickname: memberData?.nickname ?? "",
            }}
            innerRef={nicknameEditFormikRef}
            validationSchema={nicknameEditSchema}
            enableReinitialize={true}
            onSubmit={(values) => editMemberNickname(values)}
          >
            {({ values, setFieldValue }) => (
              <Form className="pt-4">
                <div className="mb-6">
                  <Field
                    name="nickname"
                    component={Input}
                    placeholder={intl.formatMessage({
                      id: "nickname",
                    })}
                    label={intl.formatMessage({
                      id: "nickname",
                    })}
                    autoComplete="off"
                    type="text"
                  />
                </div>
                <button
                  className="w-full px-4 py-2 font-semibold bg-yellow-400 rounded text-slate-950 hover:bg-yellow-500 focus:outline-none focus:ring-2 focus:ring-yellow-600"
                  type="submit"
                >
                  {intl.formatMessage({ id: "save" })}
                </button>
              </Form>
            )}
          </Formik>
        )}
      </Modal>
    </>
  );
};
