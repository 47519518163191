import { IntlShape } from "react-intl";

export const CheckIsNull = (value?: string | null) =>
  value === "" || value === undefined || value === null;

export const toAbsoluteUrl = (url: string) => {
  const newUrl = url[0] === "/" ? url : "/" + url;

  return window.location.origin + newUrl;
};

export const ResolveArrayNull = (array?: Array<any>) => {
  return array ? array : [];
};

export const IsAlphaNumeric = (e: any) => {
  const alphanumericRegex = /^[a-zA-Z0-9]+$/;
  const key = e.key;

  return alphanumericRegex.test(key);
};

export const IsKeyPressNumber = (e: any) => {
  return (
    e.code.includes("Digit") ||
    (e.code.includes("Numpad") && !isNaN(e.key)) ||
    e.ctrlKey ||
    e.code === "Backspace" ||
    e.code.includes("Arrow")
  );
};

export const CopyToClipboard = (
  text: string,
  setSwalProps: (swalProps: {
    show: boolean;
    position: string;
    text: string;
    timer: number;
    showConfirmButton: boolean;
    toast: boolean;
    width: string;
  }) => void,
  intl: IntlShape
) => {
  if (setSwalProps && intl) {
    setSwalProps({
      show: true,
      position: "bottom",
      text: intl.formatMessage({ id: "copySuccessfully" }),
      timer: 1000,
      showConfirmButton: false,
      toast: true,
      width: "15rem",
    });
  }
  navigator.clipboard.writeText(text);
};
