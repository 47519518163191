import { createAsyncThunk } from "@reduxjs/toolkit";
import { authPost } from "../../common/redux/ApiClient";
import {
  IMemberCreateRequest,
  IMemberListRequest,
  IMemberNicknameUpdateRequest,
  IMemberTopupRequest,
  IShareMemberInfoRequest,
} from "../models/request";

export const getMemberList = createAsyncThunk(
  "sample/memberlist",
  async (data: IMemberListRequest) => {
    return await authPost(`${process.env.REACT_APP_DOMAIN}/Agent3.ashx`, data);
  }
);

export const shareMemberInfo = createAsyncThunk(
  "sample/sharememberinfo",
  async (data: IShareMemberInfoRequest) => {
    return await authPost(`${process.env.REACT_APP_DOMAIN}/Agent2.ashx`, data);
  }
);

export const createNewMember = createAsyncThunk(
  "sample/createnewmember",
  async (data: IMemberCreateRequest) => {
    return await authPost(`${process.env.REACT_APP_DOMAIN}/Agent1.ashx`, data);
  }
);

export const editMemberNickname = createAsyncThunk(
  "sample/editmembernickname",
  async (data: IMemberNicknameUpdateRequest) => {
    return await authPost(`${process.env.REACT_APP_DOMAIN}/Agent2.ashx`, data);
  }
);

export const topupMember = createAsyncThunk(
  "sample/topupmember",
  async (data: IMemberTopupRequest) => {
    return await authPost(`${process.env.REACT_APP_DOMAIN}/Agent2.ashx`, data);
  }
);
