import { createContext } from "react";
import { Route, Routes } from "react-router-dom";
import { Setting } from "../../modules/setting/components/Setting";
import { Status404 } from "../../modules/statusPage/component/Status404";
export const SettingContext = createContext({});

export const SettingWrapper = () => {
  const value = {};

  return (
    <SettingContext.Provider value={value}>
      <Routes>
        <Route path="" element={<Setting />} />
        <Route path="*" element={<Status404 />} />
      </Routes>
    </SettingContext.Provider>
  );
};
