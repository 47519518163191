import { createContext } from "react";
import { Route, Routes } from "react-router-dom";
import { Report } from "../../modules/report/components/Report";
import { Status404 } from "../../modules/statusPage/component/Status404";
export const ReportContext = createContext({});

export const ReportWrapper = () => {
  const value = {};

  return (
    <ReportContext.Provider value={value}>
      <Routes>
        <Route path="" element={<Report />} />
        <Route path="*" element={<Status404 />} />
      </Routes>
    </ReportContext.Provider>
  );
};
