import { useEffect, useState } from "react";
import { LOCALES } from "../app/constant/Locales";

export const useLanguage = () => {
  const [locale, setLocale] = useState<string>(
    localStorage.getItem("locale") || LOCALES.ENGLISH
  );

  useEffect(() => {
    const handleLanguageChange = (event: CustomEvent) => {
      const newLocale = event.detail.locale;
      setLocale(newLocale);
      localStorage.setItem("locale", newLocale);
    };

    window.addEventListener(
      "languageChange",
      handleLanguageChange as EventListener
    );

    return () => {
      window.removeEventListener(
        "languageChange",
        handleLanguageChange as EventListener
      );
    };
  }, []);

  return locale;
};

export const setLanguage = (locale: string) => {
  const event = new CustomEvent("languageChange", { detail: { locale } });
  window.dispatchEvent(event);
};
