import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { sampleState } from "../models/state/SampleState";
import { getMarcas } from "./SampleCRUD";

export const sampleSlice = createSlice({
  name: "sample",
  initialState: sampleState,
  reducers: {
    clearMarcasList: (state) => {
      state.marcasList = sampleState.marcasList;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMarcas.pending, (state) => {
        state.listLoading = true;
      })
      .addCase(getMarcas.fulfilled, (state, action: PayloadAction<any>) => {
        state.listLoading = false;
        state.marcasList = action.payload;
      })
      .addCase(getMarcas.rejected, (state, action: PayloadAction<any>) => {
        state.listLoading = false;
        state.marcasList = sampleState.marcasList;
      });
  },
});
